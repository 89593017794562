import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Store } from '@ngrx/store';
import { returnAuthDone, returnOrderState, returnText, returnPageExpired } from 'src/state/selectors';
import { OrderState, extendPollingTimeOut } from 'src/helperFunctions';
import { Router } from '@angular/router';
import { SiToastRef, SiToastService, SiToastTypes } from '@simpl/siemens-brand-ng/toast';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  toastRef?: SiToastRef;

  constructor(
    private store: Store,
    private router: Router,
    private toastService: SiToastService
  ) {}

  ngOnInit(): void {
    this.store.select(returnOrderState).subscribe((_state) => {
      this.state = _state;
    });

    this.store.select(returnAuthDone).subscribe((_auth) => {
      if (_auth === false) {
        this.router.navigate(['landing']);
      }
    }).unsubscribe();

    // Setting initial polling timeout
    extendPollingTimeOut(this.store);

    // This Observer will display a toast message using the specified template when the page expires.
    this.store.select(returnPageExpired).subscribe((_expired) => {
      if (_expired) {
        this.showExpiredToast(this.toastTemplate);
      }
    });
  }

  // makes the OrderState enum available in the template
  OrderState = OrderState;
  state: OrderState = OrderState.PROCESSING;

  @ViewChild('navigation', { static: false }) navigation?: TabsetComponent;
  @ViewChild('toastTemplate', { read: TemplateRef }) toastTemplate!: TemplateRef<any>;

  /**
   * The function selects a tab by setting its "active" property to true in the navigation object.
   * @param tabId - The tabId parameter is a number that represents the ID of the tab that you
   * want to select.
   */
  selectTab(tabId: number) {
    if (this.navigation?.tabs[tabId]) {
      this.navigation.tabs[tabId].active = true;
    }
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store.select(returnText(id)).subscribe((val) => { 
      msg = val;
    }).unsubscribe();
    return msg;
  }

  /**
   * The "reload" function redirects the user to the landing page.
   * Used after page times-out to re-initialize the app.
   */
  reload(): void {
    // this.closeToast();
    window.location.assign(environment['NG_APP_FRONTEND']);
  }

  /**
   * The showExpiredToast function displays a toast message using a given template reference.
   * @param templateRef - The templateRef parameter is a reference to an Angular template. It is used
   * to specify the content of the toast message that will be displayed. The templateRef can be created
   * using the Angular TemplateRef class and can contain HTML markup and Angular directives.
   */
  showExpiredToast(templateRef: TemplateRef<any>) {
    this.toastRef = this.toastService.showToast({
      content: templateRef,
      type: SiToastTypes.DANGER,
    });
  }

/**
 * The closeToast function hides the toast message and sets the toastRef variable to undefined.
 
  closeToast() {
    this.toastRef?.hide();
    this.toastRef = undefined;
  }
*/

}
