<section>
  <div class="container" >
    <tabset [justified]="true" #navigation>
      <tab [heading]="textFetch('Start')" style="padding: 0;">
        <app-start></app-start>
      </tab>
      <tab [disabled]="!(state === OrderState.ORDER)" [heading]="textFetch('Order')">
        <app-order></app-order>
      </tab>
      <tab [disabled]="!(state === OrderState.ACTIVATE)" [heading]="textFetch('Activate')">
        <app-activate></app-activate>
      </tab>
      <tab [disabled]="!(state === OrderState.CANCEL)" [heading]="textFetch('Cancel')">
        <app-cancel-service></app-cancel-service>
      </tab>
      <tab [disabled]="!(state === OrderState.CANCEL)">
        <ng-template tabHeading>
          <span style="color: #FE8389;">{{textFetch('Compromised_VSC')}}</span>
        </ng-template>
        <app-compromised></app-compromised>
      </tab>
    </tabset>
    <ng-template let-toast="toastRef" #toastTemplate>
      <div class="expired ">
        {{ textFetch('Polling_stopped') }}
        <button type="button" class="btn btn-secondary" (click)="reload()">
          <i class="siemens-reset"></i>
        </button>
      </div>
    </ng-template>
  </div>
</section>
