<header>
  <nav
    #siHeader="siHeader"
    [cdkTrapFocus]="siHeader.navbarExpandedOrDropdownOpen"
    siHeader
    class="si-header si-header-internet fixed-top"
    [class.bg-dark]="dark"
    [class.bg-light]="!dark"
  >
    <div siHeaderContainer class="container">
      <span
        class="si-header-brand"
        aria-label="Siemens logo"
        style="text-decoration: none"
      >
        <si-logo></si-logo>
      </span>
      <button
        class="si-header-toggler"
        type="button"
        siHeaderToggler
        aria-label="Toggle navigation"
      >
        <span class="siemens-menu"></span>
      </button>
      <div class="si-header-collapse" siHeaderCollapse>
        <div class="si-header-collapse-wrapper">
          <div class="container">
            <ul siHeaderMenu class="si-header-nav-main">
              <li siHeaderItem>
                <a routerLink="/" hidden></a>
              </li>
            </ul>
            <div class="links">
              <i class="siemens-documentation"></i
              ><a
                class="link"
                href="https://wiki.siemens.com/display/SiemensCA/Virtual+Smart+Card"
                >Wiki</a
              >
            </div>
            <div
              class="language-logo"
              (click)="languageSelection(languageTemplate)"
            >
              {{ language_icon$ | async }}
            </div>
            <i
              class="siemens-login login"
              routerLink="/"
              *ngIf="(login$ | async) === false"
              tooltip="{{ GID$ | async }}"
            ></i>
            <i
              class="siemens-logout login"
              (click)="logout()"
              *ngIf="login$ | async"
              tooltip="{{ GID$ | async }}"
            ></i>
          </div>
        </div>
      </div>
      <!-- LANGUAGE MODAL -->
      <ng-template #languageTemplate>
        <div class="modal-header">
          <h4 class="modal-title">{{ textFetch("Header_Modal_Title") }}</h4>
        </div>
        <div class="modal-body">
          {{ textFetch("Header_Modal_Description") }}
        </div>
        <button
          type="button"
          (click)="changeLanguage('EN')"
          class="btn btn-free"
        >
          English
        </button>
        <button
          type="button"
          (click)="changeLanguage('DE')"
          class="btn btn-free"
        >
          Deutsch
        </button>
        <!-- <button type="button" (click)="changeLanguage('FR')" class="btn btn-free">Francais</button>
        <button type="button" (click)="changeLanguage('ES')" class="btn btn-free">Español</button>
        <button type="button" (click)="changeLanguage('PT')" class="btn btn-free">Português</button>
        <button type="button" (click)="changeLanguage('CN')" class="btn btn-free">中文</button> -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            (click)="modalRef.hide()"
          >
            <span class="siemens-close"></span>
            {{ textFetch("Header_Modal_Close") }}
          </button>
        </div>
      </ng-template>
      <br />
      <div style="color: white; font-size: x-large;">
        Virtual Smart Card (VSC) {{ textFetch("Title_modifier") }}
      </div>
    </div>
  </nav>
</header>
