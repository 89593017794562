import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnOrderState, returnText } from 'src/state/selectors';
import { MainComponent } from 'src/app/pages/main/main.component';
import { setOrderState } from 'src/state/actions';
import { OrderState } from 'src/helperFunctions';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  constructor(private store: Store, private main: MainComponent) {}

  ngOnInit(): void {
    this.store.select(returnOrderState).subscribe((val) => {
      this.orderState = val;
    });
  }

  orderState: OrderState = OrderState.PROCESSING;
  index: number = 0;
  production: boolean = environment.production;

  // makes the OrderState enum available in the template
  OrderState = OrderState;

  goToOrder() {
    this.main.selectTab(1);
  }
  goToActivate() {
    this.main.selectTab(2);
  }

  /**
   * The `cycle` function cycles through a list of states and updates the start state value in the
   * store.
   */
  cycle() {
    if (this.production === false) {
      const state = [
        OrderState.PROCESSING,
        OrderState.ORDER,
        OrderState.PROCESSING,
        OrderState.ACTIVATE,
        OrderState.PROCESSING,
        OrderState.CANCEL,
      ];
      if (this.index === state.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
      this.store.dispatch(setOrderState({ value: state[this.index] }));
    }
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }
}
