import { Language } from 'src/models/language.model';
/* eslint-disable max-len */
/* spell-checker:disable*/

export const ENGLISH: Language = {
  /* GENERAL TERMS*/
  Activate: 'Activate',
  Auth_Error: 'Authorization failed: you are not allowed to use this service!',
  Cancel: 'Cancel',
  Compromised_VSC: 'Compromised',
  Here: 'here',
  Order: 'Order',
  Proxy_Error: 'Authentication failed: please ensure that you\'re accessing this service using the company\'s internal network.',
  Redirecting: 'Redirecting you to the Start page in ',
  Seconds: ' seconds',
  Start: 'Start',
  Step: 'Step',
  Support: 'Wiki',
  Token_Expiration: 'Token expired, please reload page to re-do authentication.',
  Token_Process_Error: 'Error occurred, unable to process token requests. Please try again later.',
  Polling_stopped: 'Page refreshing paused, please reload to continue updating:',
  Title_modifier: 'Management Portal',


  /* ORDER PAGE */
  Order_checkbox: 'I confirm that I want to order a Virtual Smart Card.',
  Order_checkboxLabel: 'Order',
  Order_confirmOrder: 'Confirm Order',
  Order_costs_1: '€ per month',
  Order_description_1: 'Further information can be found ',
  Order_email_1: 'Your Virtual Smart Card order is being processed, please wait for the activation to become available. If this is your first time, please follow the instructions sent to you in an email.',
  Order_intro: 'The Virtual Smart Card currently supports managed Windows clients only.',
  Order_software: 'Receive Email & Install Software',
  Order_processing: 'Your Virtual Smart Card order is being processed.',

  /* ACTIVATE PAGE */
  Activation_active: 'Activation Processing',
  Activation_button: 'Enter PIN and Activate',
  Activation_confirm: 'Confirm Activation',
  Activation_confirmation: 'We\'re processing your activation request.',
  Activation_main: 'You must choose your own 8-digit PIN while activating the Virtual Smart Card.',
  Activation_support: 'In case of any issues, please refer to ',
  Activation_error: 'Error occurred, unable to process token activation. ',
  // temporary entries for modal
  Cancel_Activate: 'CANCEL',
  Enter_PIN: 'Please enter an 8 digit numeric PIN',
  Excessive_Length: 'Excessive Length: PIN must be 8 digits long!',
  Input_Mismatch: 'PINs do not match!',
  Insufficient_Length: 'Insufficient length: PIN must be 8 digits long!',
  Submit_Activate: 'SUBMIT',
  Token_PIN_Input: 'PIN Input',

  /* CANCELATION PAGE */
  Cancel_button: 'Cancel',
  Cancel_cancelled: 'Cancellation Processing',
  Cancel_charges: 'Your charges will end with the current month.',
  Cancel_confirm: 'Confirm Cancellation',
  Cancel_confirmation: 'I confirm that I want to cancel my Virtual Smart Card',
  Cancel_main: 'This cancels your Virtual Smart Card.',
  Cancel_second: 'Your Virtual Smart Card will be cancelled.',
  Cancel_confirmed: 'Cancellation confirmed',

  /* COMPROMISED VSC */
  Compromised_button: 'Invalidate',
  Compromised_confirmation: 'I confirm that I want to invalidate (revoke) my compromised Virtual Smart Card.',
  Compromised_heading: 'Compromised',
  Compromised_main: 'With your confirmation, your Virtual Smart Card will be deactivated and your certificates invalidated (revoked).',
  Compromised_tabs: [
    'You will no longer be able to use it.',
    'In case you have a Corporate ID Card and still want to use it, renew your certificates ',
    'You must inform your CISO about any possible misuse of your card.'
  ],
  Compromised_cancelled: 'Invalidation Processing',
  Compromised_main2: `Your Virtual Smart Card will be deactivated and all certificates invalidated (revoked).`,

  /* START PAGE */
  Start_box_activate_now: 'Activate now',
  Start_box_activate: 'Ready for activation',
  Start_box_active: 'Your VSC is active',
  Start_box_orderState: 'Eligible to order',
  Start_box_order_now: 'Order now',
  Start_box_processing: 'Your request is processing',

  /* FOOTER */
  Footer_Cookie_Notice: 'Cookie Notice',
  Footer_Corporate_Information: 'Corporate Information',
  Footer_Digital_ID: 'Digital ID',
  Footer_Privacy_Notice: 'Privacy Notice',
  Footer_Terms_of_Use: 'Terms of Use',

  /* LOGOUT */
  Logout_heading: 'Virtual Smart Card: Logged Out',
  Logout_main: 'You have been successfully logged out.',

  /* HEADER */
  Header_Modal_Close: 'Close',
  Header_Modal_Description: 'Please select your preferred language.',
  Header_Modal_Title: 'Language Selection'
};
