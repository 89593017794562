<section style="overflow-x: hidden; overflow-y: auto">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="container">
      <!-- TAB NAVIGATION -->
      <tabset [justified]="true">
        <tab [heading]="textFetch('Start')">
          <!-- PLACEHOLDER CONTENT -->
          <div class="row" aria-hidden="true">
            <div class="h1">
              <span class="skeleton-text w-100"></span>
            </div>
          </div>
          <div class="row" aria-hidden="true">
            <div class="col-8">
              <div *ngFor="let item of [1, 2]; trackBy: trackByIndex">
                <p>
                  <span class="skeleton-text line-1"></span>
                  <span class="skeleton-text line-2"></span>
                  <span class="skeleton-text line-3"></span>
                  <span class="skeleton-text line-4"></span>
                </p>
              </div>
            </div>
            <div class="col-4">
              <div [siLoading]="loading" class="spinner-style"></div>
            </div>
          </div>
        </tab>
        <tab [heading]="textFetch('Order')" [disabled]="deactivated"> </tab>
        <tab [heading]="textFetch('Activate')" [disabled]="deactivated"> </tab>
        <tab [heading]="textFetch('Cancel')" [disabled]="deactivated"> </tab>
        <tab [heading]="textFetch('Compromised_VSC')" [disabled]="deactivated"> </tab>
      </tabset>
    </div>
  </div>
</section>
