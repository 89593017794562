import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { DARK } from '../../environments/theme';
import { Store } from '@ngrx/store';
import { returnAuthDone, returnGID, returnLanguageIcon, returnText } from 'src/state/selectors';
import { setAuthDone, setLanguage, setLanguageIcon } from 'src/state/actions';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Toasts, ToastType } from 'src/helperFunctions';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  modalRef!: BsModalRef;
  login$: Observable<boolean | string>;
  GID$: Observable<string | null>;
  language_icon$: Observable<string | null>;
  dark = DARK;

  constructor(
    private store: Store,
    private router: Router,
    private modalService: BsModalService,
    private toastService: SiToastService
  ) {
    this.login$ = store.select(returnAuthDone);
    this.language_icon$ = store.select(returnLanguageIcon);
    this.GID$ = store.select(returnGID);
  }

  ngOnInit(): void {
    return;
  }

  languageSelection(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  changeLanguage(language: string): void {
    try {
      this.modalRef.hide();
      this.store.dispatch(setLanguage({ setting: language }));
      this.store.dispatch(setLanguageIcon({ value: language }));
      localStorage.setItem("language", language);
    } catch (error) {
      alert('Language not supported');
    }
  }

  async logout(): Promise<void> {
    await axios({
      method: 'get',
      url: `${environment.NG_APP_BACKEND}/logout`
    })
      .then(() => {
        this.store.dispatch(setAuthDone({ value: false }));
        this.router.navigate(['logout']);
        Toasts(
          this.toastService,
          ToastType.SUCCESS,
          this.textFetch('Logout_main'),
          5000
        );
      })
      .catch(error => {
        if (error.response) {
          /**
           * The request was made and the server responded with a status code that falls out of the range of 2xx
           * Show a generic error message and log the error to the console
           */
          Toasts(
            this.toastService,
            ToastType.ERROR,
            this.textFetch('Logout_Error'),
            5000
          );
          console.error(error.response.data);
        } else if (error.request) {
          /**
           * The request was made but no response was received
           * `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
           * Show a generic error message and log the error to the console
           */
          Toasts(
            this.toastService,
            ToastType.ERROR,
            this.textFetch('Logout_Error'),
            5000
          );
          console.error(JSON.stringify(error.request));
        } else {
          /**
           * Something happened in setting up the request that triggered an Error
           * Show a generic error message and log the error to the console
           */
          Toasts(
            this.toastService,
            ToastType.ERROR,
            this.textFetch('Logout_Error'),
            5000
          );
          console.error('Error', JSON.stringify(error.message));
        }
      });
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe(val => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }
}
