<section class="container">
  <div class="row card">
    <!-- <div class="col-1"></div> -->
    <div class="col-11">
      <!-- ELIGIBLE TO ORDER BOX -->
      <div class="boxMargin" *ngIf="orderState === OrderState.ORDER">
        <div class="startBox">
          <i class="siemens-shopping-cart" style="font-size: 60px; color: #00FFB9; padding-top: 15px;"></i>
          <h5 style="padding-top: 15px">
            {{textFetch("Start_box_orderState")}}
          </h5>
          <div style="padding-top: 5px">
            <span class="fakeLink" (click)="goToOrder()">{{textFetch("Start_box_order_now")}}</span>
          </div>
        </div>
      </div>
      <!-- READY TO ACTIVATE BOX -->
      <div class="boxMargin" *ngIf="orderState === OrderState.ACTIVATE">
        <div class="startBox">
          <i class="siemens-interaction-click" style="font-size: 60px; color: #00FFB9; padding-top: 15px;"></i>
          <h5 style="padding-top: 15px">
            {{textFetch("Start_box_activate")}}
          </h5>
          <div style="padding-top: 5px">
            <span class="fakeLink" (click)="goToActivate()">{{textFetch("Start_box_activate_now")}}</span>
          </div>
        </div>
      </div>
      <!-- SERVICE IS ACTIVE BOX -->
      <div class="boxMargin" *ngIf="orderState === OrderState.CANCEL">
        <div class="startBox">
          <i class="siemens-success" style="font-size: 70px; padding-top: 20px; color: #00FFB9;"></i>
          <h5 style="padding-top: 15px">
            {{textFetch("Start_box_active")}}
          </h5>
        </div>
      </div>
      <!-- SERVICE IS PROCESSING BOX -->
      <div class="boxMargin" *ngIf="orderState === OrderState.PROCESSING">
        <div class="startBox">          
          <!-- <i class="siemens-settings" style="font-size: 35px; padding-top: 15px; color: #00FFB9;"></i> -->
          <div [siLoading]="true" style="margin-top: 40px; color: #00FFB9;"></div>
          <h5 style="margin-top: 95px">
            {{textFetch("Start_box_processing")}}
          </h5>
        </div>
      </div>
      <!-- TESTING BUTTON -->
      <button type="button" class="btn btn-free" (click)="cycle()" *ngIf="production === false">Cycle States: {{ index }}</button> 
    </div>
  </div>
</section>
