import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnCost, returnOrderStep, returnRedirectDuration, returnText } from 'src/state/selectors';
import { MainComponent } from 'src/app/pages/main/main.component';
import { setOrderState, setOrderStep } from 'src/state/actions';
import { Observable } from 'rxjs';
import { countdown, extendPollingTimeOut, OrderState, Toasts, ToastType } from 'src/helperFunctions';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  constructor(
    private store: Store<{ cost_AG: string, cost_Energy: string }>,
    private main: MainComponent,
    private toastService: SiToastService
  ) {
    this.cost_AG$ = this.store.select(returnCost('AG'));
    this.cost_Energy$ = this.store.select(returnCost('Energy'));
  }

  ngOnInit(): void {
    this.store.select(returnOrderStep).subscribe(val => {
      this.orderStep = val;
    });
    const redirectDuration = this.store.select(returnRedirectDuration('order')).subscribe(val => {
      this.time.countDownLength = val;
    });
    redirectDuration.unsubscribe();
  }

  orderStep = 1;
  order = false;
  cost_AG$: Observable<null|string>;
  cost_Energy$: Observable<null|string>;
  time = {
    countDownLength: 1,
    count: 1
  };

  // makes the OrderState enum available in the template
  OrderState = OrderState;

  ordering(): void {
    extendPollingTimeOut(this.store);
    this.orderToken();
    this.store.dispatch(setOrderStep({ value: 2 }));
    this.store.dispatch(setOrderState({ value: OrderState.PROCESSING }));
    countdown(this.time, this.redirect.bind(this));
  }

  // Followup action for the countdown timer
  redirect(): void {
    this.main.selectTab(0);
    this.order = !this.order;
    this.store.dispatch(setOrderStep({ value: 1 }));
  };

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store.select(returnText(id)).subscribe(val => {
      msg = val;
    }).unsubscribe();
    return msg;
  }

  /**
   * POST request to the backend for a new token
   * Sets the token in store
   */
  async orderToken(): Promise<void> {
    await axios({
      method: 'post',
      url: `${environment.NG_APP_BACKEND}/token`,
      withCredentials: true,
      data: 'dummy-needed-by-tomcat-cors-filter',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .then(() => {
        // handle success
        Toasts(this.toastService, ToastType.SUCCESS, this.textFetch('Order_processing'), 5000);
      })
      .catch(error => {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        Toasts(this.toastService, ToastType.ERROR, this.textFetch('Token_Process_Error'), 5000);
        console.error('Token Order error: ');
        console.error(JSON.stringify(error.toJSON()));
      });
  }

}
