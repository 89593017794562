import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { StoreState } from 'src/models/state.model';

/**
 * This function returns the text fields in a specific language
 * @returns language.
 */
export const returnText = (textId: string) =>
  createSelector(createFeatureSelector('storeEntries'), (state: StoreState) => {
    try {
      // this was hell ==> only way to access the language object dynamically
      return (state.language as any)[textId];
    } catch (error) {
      throw new Error('Unrecognized Text ID for Language!');
    }
  });

/* DEFAULT GETTERS */
export const returnLanguageIcon = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.language_icon
);

export const returnAuthDone = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.authDone
);

export const returnOrderState = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.orderState
);

export const returnOrderStep = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.orderStep
);

export const returnActivateStep = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.activateStep
);

export const returnCancelStep = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.cancelStep
);

export const returnCompromisedStep = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.compromisedStep
);

export const returnCost = (department: string) =>
  createSelector(createFeatureSelector('storeEntries'), (state: StoreState) => {
    if (department === 'AG') {
      return state.cost_AG;
    } else {
      return state.cost_Energy;
    }
  });

export const returnLanguage = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.language
);

export const returnRedirectDuration = (type: string) =>
  createSelector(createFeatureSelector('storeEntries'), (state: StoreState) => {
    switch (type) {
      case 'order':
        // @ts-ignore
        return state.redirectDuration.order;
      case 'activate':
        // @ts-ignore
        return state.redirectDuration.activate;
      case 'cancel':
        // @ts-ignore
        return state.redirectDuration.cancel;
      case 'compromised':
        // @ts-ignore
        return state.redirectDuration.compromised;
      default:
        alert('Unrecognized redirect type!');
        break;
    }
  });

/* CODE FROM FIRST VERSION */

/**
 * It returns the encoded authorization code.
 * @returns encoded authorization code.
 */
export const returnAuthCode = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.authCode
);

/**
 * It returns the encoded version of the frontend variable for MyID
 * @returns encoded frontend variable.
 */
export const returnEncodedRedirectUri = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => encodeURIComponent(environment.NG_APP_FRONTEND)
);

/**
 * @returns frontend URL variable
 */
export const returnRedirectUri = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => environment.NG_APP_FRONTEND
);

/**
 * It returns an object that contains a list of token requests
 * @returns all token requests
 */
export const returnTokenRequests = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.tokenRequests
);

/**
 * It returns the value of the variable token
 * @returns token
 */
export const returnToken = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.token
);

/**
 * The function returns the value of the variable PIN
 * @returns PIN
 */
export const returnPIN = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.PIN
);

/**
 * @returns value of duration
 */
export const returnDuration = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.duration
);

/** @returns value of pollingTimeOut */
export const returnPollingTimeOut = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.pollingTimeOut
);

/** @returns value of pollingTimeOutDuration */
export const returnPollingTimeOutDuration = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.pollingTimeOutDuration
);

/** @returns value of pageExpired */
export const returnPageExpired = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.pageExpired
);

/** @returns value of GID */
export const returnGID = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.GID
);

/** @returns value of deactivate */
export const returnDeactivate = createSelector(
  createFeatureSelector('storeEntries'),
  (state: StoreState) => state.deactivate
);
